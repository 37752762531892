import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { NavLink as BaseNavLink, useLocation } from "react-router-dom";
import { useOrochi } from "../context/OrochiContext";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../i18n";
import { useMediaQuery } from "react-responsive";
import ResponsiveImage from "./Img/ResponsiveImage";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const {
    dispatch,
    state: { navbarToggle, isScrolling },
  } = useOrochi();
  const isMobile = useMediaQuery({ maxWidth: 1050 });
  // const onMouseMove = (action) => {
  //   if (action === "leave") {
  //     ref.current = true;
  //     setTimeout(() => {
  //       ref.current === true &&
  //         dispatch({
  //           type: "navbar-toggle",
  //           payload: false,
  //         });
  //     }, 5000);
  //   } else if (action === "over") {
  //     ref.current = false;
  //   }
  // };
  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <>
      {(location.pathname === "/" ||
        location.pathname === "/fr" ||
        location.pathname === "/menu" ||
        location.pathname === "/menu/fr" ||
        location.pathname === "/about" ||
        location.pathname === "/privacy-policy" ||
        location.pathname === "/terms-of-use" ||
        location.pathname === "/the-legend" ||
        location.pathname === "/contact") && (
        <NavbarDiv
          theme={{ navbarToggle: !isMobile || navbarToggle, isScrolling }}
          // onMouseLeave={() => onMouseMove("leave")}
          // onMouseOver={() => onMouseMove("over")}
        >
          {/* <NavLink to="/order">
        <div>Order</div>
      </NavLink> */}
          {/* <NavLink to="/" end>
        <img src={logo} alt="logo" />
      </NavLink> */}
          <NavLink
            to="/"
            end
            onClick={() =>
              isMobile &&
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
          >
            <div>{t("nav.two")}</div>
          </NavLink>
          <NavLink
            to="/menu"
            onClick={() =>
              isMobile &&
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
          >
            <div>{t("nav.one")}</div>
          </NavLink>
          {/* <NavLink
            onClick={() =>
              isMobile &&
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
            to={`/pdf/valentines-day.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div>
              {i18n.language === "en" ? "Valentines" : "Saint-Valentin"}
            </div>
          </NavLink> */}
          <NavLink
            to="/about"
            onClick={() =>
              isMobile &&
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
          >
            <div>{t("nav.three")}</div>
          </NavLink>
          <NavLink
            to="/contact"
            onClick={() =>
              isMobile &&
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
          >
            <div>{t("nav.four")}</div>
          </NavLink>
          <ReserveButton
            onClick={() =>
              dispatch({
                type: "navbar-toggle",
                payload: false,
              })
            }
            href="https://widgets.libroreserve.com/WEB/QC016855598727/book"
            target="_blank"
            rel="noreferrer"
          >
            <div>{t("reserve.button")}</div>
          </ReserveButton>
          <NavLink
            onClick={() => {
              handleLanguageChange(i18n.language === "en" ? "fr" : "en");
              dispatch({
                type: "navbar-toggle",
                payload: false,
              });
            }}
          >
            <div>{i18n.language === "en" ? "fr" : "en"}</div>
          </NavLink>
          {isMobile && (
            <ResponsiveImage src={"menu/MENU_PATTERN.webp"} alt="navbar-bg" />
          )}
        </NavbarDiv>
      )}
      {/* <Logo
        theme={{
          navbarToggle,
          isScrolling: location.pathname === "/" ? logoIsVisible : false,
        }}
      >
        <a href={`/#${t("section1")}`}>
          <img src={"/img/logos/drunken-dragon-bottom1.svg"} alt="logo" />
        </a>
      </Logo> */}
    </>
  );
};
const slide = keyframes`
from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;
const slideBack = keyframes`
from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;
const NavLink = styled(BaseNavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--silver);
  text-decoration: none;
  padding: 0;
  height: 180px;
  & > div,
  & > a {
    font-weight: 300;
    text-transform: uppercase;
  }
  & > div::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--silver);
    transition: width 0.3s ease-in;
  }
  & > div:hover::after {
    width: 100%;
  }
  &:hover {
    transition: ease-in-out 0.3s;
    color: var(--silver);
    font-size: 105%;
  }
  &.active {
    /* padding: 20px; */
    /* border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    color: #000000;
    background-color: var(--silver); */
  }
  & > img {
    height: 70px;
    width: 70px;
    color: var(--silver);
    cursor: pointer;
    &:hover {
      transition: all 0.2s;
      scale: 1.1;
    }
  }
  &:nth-child(7),
  &:nth-child(6) {
    display: none;
    &.active {
      padding: 0;
      border-radius: 0;
      background: none;
    }
  }
  &:nth-child(7) {
    margin-top: 32px;
  }
  @media screen and (max-width: 1050px) {
    /* start of phone and medium tablet styles */
    height: auto;
    & > div {
      padding: 10px;
    }
    &.active {
      height: auto;
      border-radius: 20px;
      padding: 0;
      border-radius: 0;
      background: none;
      color: var(--silver);
    }
    &:hover {
      font-size: 100%;
    }
    & > img {
      height: 40px;
      width: 40px;
      &:hover {
        height: 45px;
        width: 45px;
      }
    }
    &:nth-child(7),
    &:nth-child(7) {
      display: flex;
    }
  }
`;
const ReserveButton = styled.a`
  display: none;
  justify-content: center;
  align-items: center;
  color: var(--silver);
  text-decoration: none;
  & > div {
    font-weight: 300;
    text-transform: uppercase;
  }
  @media screen and (max-width: 1050px) {
    display: flex;
  }
`;
const NavbarDiv = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 150px;
  opacity: ${({ theme }) => (theme.navbarToggle ? "1" : "0")};
  z-index: ${({ theme }) => (theme.navbarToggle ? "1" : "-1")};
  transition: all 0.4s ease-in;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-repeat: no-repeat;
  animation: ${({ theme }) =>
    theme.navbarToggle === true &&
    css`
      ${slide} 0.5s ease-in-out forwards
    `};
  animation: ${({ theme }) =>
    theme.navbarToggle === false &&
    css`
      ${slideBack} 0.5s ease-in-out backwards
    `};
  z-index: 10;
  ${NavLink} {
    pointer-events: ${({ theme }) => theme.navbarToggle === false && "none"};
  }
  & > img {
    position: absolute;
    height: 100%;
    max-height: 250px;
    object-fit: cover;
    object-position: 100% 100%;
    width: 100%;
    display: ${({ theme }) =>
      theme.navbarToggle === false ? "none" : "block"};
    bottom: 0;
    right: 0;
    z-index: 11;
    pointer-events: none;
  }
  @media screen and (max-width: 1050px) {
    /* start of phone and medium tablet styles */
    background: var(--black);
    flex-flow: column nowrap;
    top: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    gap: 20px;
    z-index: 11;
    pointer-events: ${({ theme }) =>
      theme.navbarToggle === false ? "none" : "unset"};
    transition: all 0.3s ease-in;
  }
`;
// const Logo = styled.div`
//   position: fixed;
//   z-index: 12;
//   height: 100%;
//   width: 100%;
//   max-height: 120px;
//   opacity: ${({ theme }) => (theme.isScrolling === false ? "1" : "0")};
//   transition: opacity 0.4s ease-in, all 0.3s ease;
//   padding-left: 147px;
//   display: flex;
//   justify-content: center;
//   & > a {
//     position: relative;
//     width: 100%;
//     max-width: 480px;
//     & > img {
//       height: 100%;
//       width: 100%;
//       object-fit: contain;
//     }
//   }
//   @media screen and (min-width: 991px) and (max-width: 1200px) {
//     /* start of medium tablet styles */
//     padding-left: 127px;
//     & > a {
//       /* max-width: 350px; */
//     }
//   }
//   @media screen and (max-width: 1050px) {
//     top: 40px;
//     max-width: 240px;
//     max-height: 80px;
//     z-index: ${({ theme }) => (theme.navbarToggle ? "10" : "12")};
//     left: 50%;
//     transform: translate(-50%, -50%);
//     padding: 0;
//     & > a {
//       max-width: 350px;
//     }
//   }
// `;

export default Navbar;
